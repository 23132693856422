import { createI18n, useI18n } from 'vue-i18n';
import enGB from '@/locales/en-GB.json';
import frFR from '@/locales/fr-FR.json';
import deDE from '@/locales/de-DE.json';
import plPL from '@/locales/pl-PL.json';
import jaJP from '@/locales/ja-JP.json';
import cyGB from '@/locales/cy-GB.json';

// Define a schema for the translations based on en-gb.json as the master
export type MessageSchema = typeof enGB;
type SupportedLanguages = 'en-GB' | 'ja-JP' | 'cy-GB' | 'fr-FR' | 'de-DE' | 'pl-PL';

// Add all the available language options
const i18n = createI18n<[MessageSchema], SupportedLanguages>({
  locale: 'en-GB',
  fallbackLocale: 'en-GB',
  messages: {
    'en-GB': enGB,
    'ja-JP': {} as MessageSchema, // empty initially
    'cy-GB': {} as MessageSchema,
    'fr-FR': {} as MessageSchema,
    'de-DE': {} as MessageSchema,
    'pl-PL': {} as MessageSchema,
  },
});

/**
 * Helper function for switching language
 * @param lang, e.g. 'fr', 'fr-FR'
 * Used here to load languages other than en-GB but can also be used in a component if required.
 */
export async function setI18nLanguage(lang: SupportedLanguages) {
  // Is the language pack loaded?
  const messages = i18n.global.messages;
  if (!messages[lang] || Object.keys(messages[lang]).length === 0) {
    let languagePack: MessageSchema;

    // Extract the first two characters of the language code (browser settings report fr and fr-FR, so keep support for variations later)
    const langPrefix = lang.substring(0, 2);
    switch (langPrefix) {
      case 'fr':
        languagePack = frFR;
        break;
      case 'de':
        languagePack = deDE;
        break;
      case 'pl':
        languagePack = plPL;
        break;
      case 'ja':
        languagePack = jaJP;
        break;
      case 'cy':
        languagePack = cyGB;
        break;
      default:
        languagePack = enGB;
        break;
    }

    // Use the Vue I18n API to merge the new messages
    i18n.global.setLocaleMessage(lang, languagePack);
  }

  // Change the locale
  i18n.global.locale = lang;
}

const deviceLanguage = window.navigator.language as SupportedLanguages;
(async function initializeI18n() {
  if (deviceLanguage !== 'en-GB') {
    await setI18nLanguage(deviceLanguage);
  }
})();

/**
 * Helper function for simpler usage in components
 * @usage
 * import { useTranslate } from '@/locales/i18n';
 * const translate = useTranslate();
 *
 * // In <script>
 * const title = translate('survey.defaultTitle')
 * // In <template>
 * {{ translate('survey.defaultTitle') }}
 */
export function useTranslate() {
  const { t: translate } = useI18n<{ message: MessageSchema }>({
    useScope: 'global',
    inheritLocale: true,
  });
  return translate;
}

export default i18n;
